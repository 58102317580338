import React, { useState, useEffect } from 'react';
import './App.css'; // Assuming you save your CSS in Timeline.css

const Timeline = () => {
    const startDate = new Date("2024-01-15"); // End date of the timeline

    const [currentDay, setCurrentDay] = useState(0);

    useEffect(() => {
        const today = new Date();
        // truncate to days
        const daysSinceStart = (today - startDate) / (1000 * 3600 * 24);
        const truncatedDaysSinceStart = Math.floor(daysSinceStart);
        setCurrentDay(truncatedDaysSinceStart);
    }, []);

    return (
        <div className="timeline-container">
          <div className="rectangle">
           <h1>{currentDay} days</h1>
          </div>
          <ul className="timeline">
            <li><time datetime="1986-06">30 days</time>漫画家用紙と道具を買う</li>
            <li><time datetime="1988-10">90 days</time>ゲームボーイアドバンスSP <br/> ドラゴンボールZ <br/>THE LEGACY OF GOKU II </li>
            <li><time datetime="1988-10">180 days</time>ポケットモンスター 青 <br/> </li>
            <li><time datetime="1989-04">270 days</time>ポケットモンスター ファイアレッド</li>
            <li><time datetime="1989-09">360 days</time>ポケットモンスター リーフグリーン </li>
          </ul>
          <div className="current-day-marker" style={{ left: `${currentDay}%` }} />
        </div>
    );
};

export default Timeline;
